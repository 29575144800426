// import moment from 'moment'


jQuery(document).ready(function ($) {


  $("#damiano-david-website-signup").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        $('#damiano-david-website-signup').html('<p>Thank you for signing up</p>');
      },
      error: function (err) {
        // Do things when submission has an error.
        alert("An error has occured!");
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
    $("#ts-for-" + $(this).attr('id')).prop("checked", $(this).is(':checked'));
  });
});